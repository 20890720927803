/* eslint-disable @typescript-eslint/ban-types */
/**
 *	===============================================================================================
 *		 ____ _____ _   _          _______     __                 _____           _     _    _
 *		|  _ \_   _| \ | |   /\   |  __ \ \   / /                / ____|         | |   | |  | |
 *		| |_) || | |  \| |  /  \  | |__) \ \_/ /__  _ __   ___  | |  __ _ __ ___ | |__ | |__| |
 *		|  _ < | | | . ` | / /\ \ |  _  / \   / _ \| '_ \ / _ \ | | |_ | '_ ` _ \| '_ \|  __  |
 *		| |_) || |_| |\  |/ ____ \| | \ \  | | (_) | | | |  __/ | |__| | | | | | | |_) | |  | |
 *		|____/_____|_| \_/_/    \_\_|  \_\ |_|\___/|_| |_|\___|  \_____|_| |_| |_|_.__/|_|  |_|
 *
 *	===============================================================================================
 *
 * @author	BINARY one GmbH
 * @license Unlicensed
 */

import { ENV } from '@libTs/env';
import { createVueApp } from '@libTs/vue-base';
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import { BASEURL, SITEURL } from '@libTs/cms.globals';
// import { SliderHelper } from './helpers/slider.helper';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { OverlaysService } from './services/overlays.service';
import { OverlayDirective } from './directives/v-overlay.directive';
import 'img-comparison-slider';
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Akkordeon from './components/akkordeon.vue';
import Lightbox from './components/lightbox.vue';
import { LangSwitcherDirective } from './directives/v-lang-switcher.directive';

import './animations';




// -----------------------------------------------------------
// DEBUG output

if ( ENV.debug ) {
  console.group( 'Environment loaded' );
  console.log( ENV );
  console.log(
    ' -----------------------------------\n',
    ` BASEURL:\t\t${BASEURL}\n`,
    ` SITEURL:\t\t${SITEURL}\n`,
    '-----------------------------------'
  );
  console.groupEnd();
}

// -----------------------------------------------------------
// Vue app

const app = createVueApp(
  // ----------------------------
  // App config
  // It is similar but not equal to "new Vue( { ... } );"
  {
    el: '#vue-app',
    services: [
      OverlaysService,
    ],
    directives: [
      OverlayDirective,
      LangSwitcherDirective
    ],
    components: {
      VueGallerySlideshow,
      Lightbox,
      Akkordeon
    },
    modules: []
  }
);

Vue.config.productionTip = false;

// Tell Vue that the web component is present.
Vue.config.ignoredElements = [/img-comparison-slider/];

// Add Axios plugin for AJAX requests
Vue.use( VueAxios, axios );

$( () => {
  gsap.registerPlugin( ScrollToPlugin );
} );

gsap.registerPlugin( ScrollTrigger );

function reloaded() {
  ScrollTrigger.refresh( true );
}
$( window ).on( 'load', reloaded );

// -----------------------------------------------------------
// Slick Carousel initialization

// const sliderHelper = new SliderHelper( '.slider' );

// sliderHelper.initSliders();

