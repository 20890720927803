var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images.length > 0
    ? _c(
        "div",
        { staticClass: "gallery" },
        [
          _vm._l(_vm.images.slice(0, 3), function(image, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "bild",
                style: {
                  "background-image": "url(" + image.url + ")",
                  "z-index": index * -1 + 100,
                  transform: "scale(" + Math.pow(0.92, index) + ")"
                },
                on: {
                  click: function($event) {
                    index = 0
                    _vm.open = true
                  }
                }
              },
              [
                _c("div", { staticClass: "filter-layer" }),
                _vm._v(" "),
                index == 0
                  ? _c(
                      "button",
                      {
                        staticClass: "open-button",
                        on: {
                          click: function($event) {
                            index = 0
                            _vm.open = true
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa-solid fa-plus" })]
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _c("Transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.open,
                    expression: "open"
                  }
                ],
                staticClass: "lightbox"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "close-button",
                    on: {
                      click: function($event) {
                        _vm.open = false
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa-solid fa-xmark" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "prev-button",
                    on: {
                      click: function($event) {
                        return _vm.previousImage()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa-solid fa-chevron-left" })]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "lightbox-container" }, [
                  _c("div", {
                    staticClass: "lightbox-bild",
                    style: {
                      "background-image":
                        "url(" + _vm.images[_vm.index].url + ")"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "lightbox-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.images[_vm.index].text) +
                        "\n          "
                    ),
                    _c("div", { staticClass: "lightbox-number" }, [
                      _vm._v(
                        _vm._s(_vm.index + 1) + "/" + _vm._s(_vm.images.length)
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "next-button",
                    on: {
                      click: function($event) {
                        return _vm.nextImage()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa-solid fa-chevron-right" })]
                )
              ]
            )
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }