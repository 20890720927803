import { render, staticRenderFns } from "./akkordeon.vue?vue&type=template&id=6bed1c5c&"
import script from "./akkordeon.vue?vue&type=script&lang=ts&"
export * from "./akkordeon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Adrian\\Documents\\Projects\\maert-craft\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6bed1c5c')) {
      api.createRecord('6bed1c5c', component.options)
    } else {
      api.reload('6bed1c5c', component.options)
    }
    module.hot.accept("./akkordeon.vue?vue&type=template&id=6bed1c5c&", function () {
      api.rerender('6bed1c5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ts/components/akkordeon.vue"
export default component.exports